import React, { useContext, useState } from "react";
import { Link, Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import { useFirestore } from "../firebase/FirestoreContext";
import { getDoc, doc, collection, getDocs } from "firebase/firestore";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const Stores = () => {
  const environment = useContext(EnvContext);
  const location = useLocation();
  const isOutletEmpty = location.pathname === "/main/stores";

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // get number of stores from firestore collection Stores/StoresList/DataBase
  const db = useFirestore(); // Get Firestore instance from context
  const { id } = useParams();
  const [stores, setStores] = useState([]);
  const navigate = useNavigate();

  return (
    <div>
      <div style={styles.header}>
        <h2>{environment === "staging" && <span style={{ background: "#dae121", marginRight: 5 }}>Staging</span>}Sellers</h2>
        <div style={styles.buttonGroup}>
          <Link to={`/main/stores/new`}>
            <button style={styles.button}>New Seller</button>
          </Link>
          <Link to={`/main/stores/edit`}>
            <button style={styles.button}>Edit Seller</button>
          </Link>
        </div>
      </div>
      <div style={{ padding: "10px" }}>{!isOutletEmpty && <Outlet />}</div>
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "white",
    borderBottom: "1px solid #ccc",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  buttonGroup: {
    display: "flex",
    gap: "10px", // Space between buttons
  },
};

export default Stores;
