import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useFirestore } from "../../firebase/FirestoreContext";
import { getDocs, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { FormControl, OutlinedInput, InputAdornment } from "@mui/material";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";
import TablePagination from "@mui/material/TablePagination";
import { TableSortLabel } from "@mui/material";

const isSooperDealActive = (sooperdeal_start_date, sooperdeal_expiration_date, sooperdeal_price) => {
  const now = new Date();
  const start = new Date(sooperdeal_start_date);
  const end = new Date(sooperdeal_expiration_date);

  // Only show the flame if the deal is active based on DB-confirmed data
  if (!sooperdeal_price || now < start || now > end) return false;

  return true;
};

const SooperDeals = () => {
  const environment = useContext(EnvContext);
  const inputRefs = useRef({});

  const { id } = useParams();
  const db = useFirestore();

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [products, setProducts] = useState([]);
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Sorting
  const [sortConfig, setSortConfig] = useState({
    key: null, // Column to sort by
    direction: "asc", // Sort direction
  });

  const formatUID = (uid, totalLength = 8) => String(uid).padStart(totalLength, "0");

  //
  const handleKeyDown = (e, rowIndex, columnIndex) => {
    if (e.key === "Tab") {
      e.preventDefault(); // Prevent default Tab behavior

      // Determine if Shift key is pressed
      const direction = e.shiftKey ? -1 : 1;
      const nextRow = rowIndex + direction;

      // Focus the next input in the same column, if it exists
      const nextInput = inputRefs.current[`${nextRow}-${columnIndex}`];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  //
  useEffect(() => {
    const fetchSooperDeals = async () => {
      if (!id) return;
      setLoading(true);
      try {
        const storeCollectionRef = collection(db, `${collectionPath}Stores/StoresList/DataBase`);
        const storeSnapshot = await getDocs(storeCollectionRef);
        const storeData = storeSnapshot.docs.find((doc) => doc.id === id);
        setStore(storeData ? storeData.data() : null);

        // Fetch only Sooper Deals listings for this specific seller
        const listingsCollectionRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);
        const listingsSnapshot = await getDocs(listingsCollectionRef);
        const storeListings = listingsSnapshot.docs
          .filter((doc) => doc.id.startsWith(`${id}_`)) // Filter listings for this seller
          .map((doc) => ({ id: doc.id.split("_")[1], ...doc.data() })); // Extract product ID and other Sooper Deal data

        setProducts(storeListings); // Set the listings (Sooper Deal data)
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSooperDeals();
  }, [id, db, collectionPath]);

  //
  const handleReset = async (productId) => {
    if (window.confirm("Are you sure you want to reset this Sooper Deal?")) {
      try {
        const docId = `${id}_${productId}`; // Generate the document ID
        const listingDocRef = doc(db, `${collectionPath}Listings/ListingsList/DataBase`, docId);

        // Clear all the Sooper Deal related fields by setting them to null
        const sooperDealResetFields = {
          sooperdeal_price: null, // Reset price
          sooperdeal_start_date: null, // Reset start date
          sooperdeal_expiration_date: null, // Reset expiration date
          sooperdeal_limit: null, // Reset unit limit
          sooperdeal_limit_per_order: null, // Reset limit per order
        };

        // Update the document in Firestore with the reset fields
        await updateDoc(listingDocRef, sooperDealResetFields);

        // Update the UI state to reflect the reset
        const updatedProducts = products.map((product) =>
          product.id === productId
            ? {
                ...product,
                sooperdeal_price: null,
                sooperdeal_start_date: null,
                sooperdeal_expiration_date: null,
                sooperdeal_limit: null,
                sooperdeal_limit_per_order: null,
              }
            : product
        );
        setProducts(updatedProducts);
      } catch (error) {
        console.error("Error resetting Sooper Deal:", error);
      }
    }
  };

  const handleInputChange = (productId, field, value, type = "text") => {
    const updatedProducts = products.map((product) => {
      if (product.id === productId) {
        let updatedValue = value;

        // Convert to number if the input type is 'number'
        if (type === "number") {
          updatedValue = parseFloat(value) || 0; // Use parseFloat for number fields
        }

        // Convert date fields to ISO string
        if (type === "date" || field.includes("date")) {
          updatedValue = value;
        }

        return { ...product, [field]: updatedValue };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const listingRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);

      for (const product of products) {
        const docId = `${id}_${product.id}`; // Prefix for Sooper Deal entries.
        const listingDocRef = doc(listingRef, docId);
        const listingDoc = await getDoc(listingDocRef);

        const dataToSave = {
          ...product,
        };

        if (listingDoc.exists()) {
          await updateDoc(listingDocRef, dataToSave);
        } else {
          await setDoc(listingDocRef, dataToSave);
        }
      }
    } catch (error) {
      console.error("Error saving Sooper Deal:", error);
    } finally {
      setLoading(false);
    }
  };

  // Pagination
  const sortedProducts = [...products].sort((a, b) => {
    const order = sortConfig.direction === "asc" ? 1 : -1;
    const aValue = a[sortConfig.key] || "";
    const bValue = b[sortConfig.key] || "";

    if (typeof aValue === "number" && typeof bValue === "number") {
      return (aValue - bValue) * order;
    }

    return aValue.toString().localeCompare(bValue.toString()) * order;
  });

  const paginatedProducts = sortedProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  // Sorting
  const handleSortRequest = (property) => {
    const isAsc = sortConfig.key === property && sortConfig.direction === "asc";
    setSortConfig({ key: property, direction: isAsc ? "desc" : "asc" });
  };

  return (
    <div style={styles.container}>
      {store ? (
        <>
          <div style={styles.header}>
            <h2>
              {environment === "staging" && <span style={{ background: "#dae121", marginRight: 5, fontWeight: "600" }}>Staging</span>}{" "}
              <span style={{ fontWeight: "300" }}>Sooper Deals for </span>
              <span style={{ fontWeight: "600" }}>{store?.seller_name}</span>
            </h2>
            <div style={styles.buttonGroup}>
              <Link to={`/main/listings/edit/${id}`}>
                <button style={styles.button}>Edit Listing</button>
              </Link>
              <Link to={`/main/listings/sooper-deals/${id}`}>
                <button style={styles.button}>Sooper Deals</button>
              </Link>
            </div>
          </div>

          {products.length > 0 ? (
            <form onSubmit={saveChanges} style={styles.form}>
              <div style={{ padding: "20px" }}>
                <table style={styles.table}>
                  <thead>
                    <tr style={{ backgroundColor: "rgba(49,57,66,.02)" }}>
                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "product_name"}
                          direction={sortConfig.key === "product_name" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("product_name")}
                        >
                          Product Name
                        </TableSortLabel>
                      </th>
                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "brand_name"}
                          direction={sortConfig.key === "brand_name" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("brand_name")}
                        >
                          Brand
                        </TableSortLabel>
                      </th>
                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "sooperdeal_price"}
                          direction={sortConfig.key === "sooperdeal_price" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("sooperdeal_price")}
                        >
                          Sooper Deal Price
                        </TableSortLabel>
                      </th>
                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "sooperdeal_start_date"}
                          direction={sortConfig.key === "sooperdeal_start_date" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("sooperdeal_start_date")}
                        >
                          Start Date
                        </TableSortLabel>
                      </th>
                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "sooperdeal_expiration_date"}
                          direction={sortConfig.key === "sooperdeal_expiration_date" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("sooperdeal_expiration_date")}
                        >
                          Expiration Date
                        </TableSortLabel>
                      </th>
                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "sooperdeal_limit"}
                          direction={sortConfig.key === "sooperdeal_limit" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("sooperdeal_limit")}
                        >
                          Unit Limit
                        </TableSortLabel>
                      </th>
                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "sooperdeal_limit_per_order"}
                          direction={sortConfig.key === "sooperdeal_limit_per_order" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("sooperdeal_limit_per_order")}
                        >
                          Limit per Order
                        </TableSortLabel>
                      </th>
                      <th style={styles.th}>Delete Deal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedProducts.map((product, rowIndex) => (
                      <tr key={product.id}>
                        <td style={{ ...styles.td, minWidth: "200px" }}>
                          {isSooperDealActive(
                            product?.sooperdeal_start_date,
                            product?.sooperdeal_expiration_date,
                            product?.sooperdeal_price,
                            product?.sooperdeal_limit
                          ) && (
                            <div
                              style={{
                                position: "absolute",
                                marginLeft: -25,
                                marginTop: -3,
                              }}
                            >
                              <dotlottie-player
                                src="https://lottie.host/02410fb0-fea9-4f42-8988-2b1fdbee0e1c/Ub51tC0G7m.json"
                                background="transparent"
                                speed="1"
                                loop
                                autoplay
                                style={{
                                  height: "20px",
                                  width: "20px",
                                }}
                              ></dotlottie-player>
                            </div>
                          )}
                          {product.product_name || ""}
                          <br />
                          <span style={{ fontSize: 10, color: "gray" }}>UID# {`P${formatUID(product.product_uid)}`}</span>
                        </td>
                        <td
                          style={{
                            ...styles.td,
                            display: "flex",
                            padding: "8px 0px",
                          }}
                        >
                          {product.brand_name || ""}
                        </td>
                        <td style={styles.td}>
                          <FormControl fullWidth>
                            <OutlinedInput
                              name="sooperdeal_price"
                              type="number"
                              value={product.sooperdeal_price || ""}
                              onChange={(e) => handleInputChange(product.id, "sooperdeal_price", e.target.value, "number")}
                              onKeyDown={(e) => handleKeyDown(e, rowIndex, 2)}
                              inputRef={(ref) => (inputRefs.current[`${rowIndex}-2`] = ref)}
                              style={{ width: "150px" }}
                              size="small"
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                          </FormControl>
                        </td>
                        <td style={styles.td}>
                          <input
                            name="sooperdeal_start_date"
                            type="datetime-local"
                            value={product.sooperdeal_start_date || ""}
                            onChange={(e) => handleInputChange(product.id, "sooperdeal_start_date", e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, rowIndex, 3)}
                            ref={(ref) => (inputRefs.current[`${rowIndex}-3`] = ref)}
                            style={styles.formInput}
                          />
                        </td>
                        <td style={styles.td}>
                          <input
                            name="sooperdeal_expiration_date"
                            type="datetime-local"
                            value={product.sooperdeal_expiration_date || ""}
                            onChange={(e) => handleInputChange(product.id, "sooperdeal_expiration_date", e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, rowIndex, 4)}
                            ref={(ref) => (inputRefs.current[`${rowIndex}-4`] = ref)}
                            style={styles.formInput}
                          />
                        </td>
                        <td style={styles.td}>
                          <input
                            name="sooperdeal_limit"
                            type="number"
                            value={product.sooperdeal_limit || ""}
                            onChange={(e) => handleInputChange(product.id, "sooperdeal_limit", e.target.value, "number")}
                            onKeyDown={(e) => handleKeyDown(e, rowIndex, 5)}
                            ref={(ref) => (inputRefs.current[`${rowIndex}-5`] = ref)}
                            style={{ ...styles.formInput, width: "60px" }}
                          />
                        </td>
                        <td style={styles.td}>
                          <input
                            name="sooperdeal_limit_per_order"
                            type="number"
                            value={product.sooperdeal_limit_per_order || ""}
                            onChange={(e) => handleInputChange(product.id, "sooperdeal_limit_per_order", e.target.value, "number")}
                            onKeyDown={(e) => handleKeyDown(e, rowIndex, 6)}
                            ref={(ref) => (inputRefs.current[`${rowIndex}-6`] = ref)}
                            style={{ ...styles.formInput, width: "60px" }}
                          />
                        </td>

                        <td style={{ ...styles.td, cursor: "pointer" }}>
                          <span style={{}} onClick={() => handleReset(product.id)}>
                            Reset
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  type="submit"
                  style={{
                    ...styles.button,
                    backgroundColor: loading ? "silver" : "#478169",
                  }}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
                <TablePagination
                  component="div"
                  count={products.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </div>
            </form>
          ) : (
            <p style={styles.noProducts}>No products available.</p>
          )}
        </>
      ) : (
        <p style={styles.loadingText}>Loading store data...</p>
      )}
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "white",
    borderBottom: "1px solid #ccc",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  buttonGroup: {
    display: "flex",
    gap: "10px",
  },
  storeListItem: {
    display: "flex",
    padding: "10px 0",
    borderBottom: ".5px solid silver",
    width: "100%",
    cursor: "pointer",
  },
  //
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tabButton: {
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 5,
    borderRadius: "5px 5px 0 0",
    fontSize: 12,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
    width: 100,
    marginRight: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
    textAlign: "left",
    backgroundColor: "rgba(255,255,255,.8)",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  th: {
    padding: "10px 0",
    textAlign: "left",
    borderBottom: ".5px solid silver",
    marginBottom: 20,
    fontSize: 12,
    minWidth: 70,
  },
  td: {
    padding: "5px 5px",
  },
};
export default SooperDeals;
