import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFirestore } from "../../firebase/FirestoreContext";
import { getDoc, doc, updateDoc, deleteDoc, setDoc, collection, getDocs, query, where } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const EditUser = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const { id } = useParams(); // The user ID from the URL
  const navigate = useNavigate();

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    role: "",
    suspended: false,
  });
  const [loading, setLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentTab, setCurrentTab] = useState("userDetails");
  const [walletLogs, setWalletLogs] = useState([]);
  const [userOrders, setUserOrders] = useState([]);
  const [expandedOrderId, setExpandedOrderId] = useState(null);

  // Fetch the user data from Firestore only once
  useEffect(() => {
    fetchUserData();
    fetchUserWallet();
  }, []);

  const toggleOrderDetails = (orderId) => {
    setExpandedOrderId((prevId) => (prevId === orderId ? null : orderId));
  };

  const fetchUserData = async () => {
    if (id) {
      const userDocRef = doc(db, `Users/UsersList/DataBase`, id);

      try {
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData(data); // Only set state once
        } else {
          console.error("No such user document!");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    }
  };

  const fetchUserOrders = async () => {
    try {
      const ordersQuery = query(collection(db, `${collectionPath}Orders/OrdersList/DataBase`), where("user_id", "==", id));
      const querySnapshot = await getDocs(ordersQuery);
      const orders = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUserOrders(orders);
    } catch (error) {
      console.error("Error fetching user orders:", error);
    }
  };

  const fetchUserWallet = async () => {
    if (id) {
      // Create the collection reference
      const userWalletCollectionRef = collection(db, `${collectionPath}Wallets/UserWallets/DataBase`);

      // Create a query with the `where` clause
      const walletQuery = query(userWalletCollectionRef, where("user_uid", "==", id));

      try {
        // Fetch the documents using the query
        const querySnapshot = await getDocs(walletQuery);
        if (!querySnapshot.empty) {
          let walletData = [];
          querySnapshot.forEach((doc) => {
            walletData.push({ ...doc.data(), id: doc.id });
          });

          // Sort the walletData array by `date_created` in descending order
          walletData.sort((a, b) => {
            const dateA = a.date?.toDate(); // Convert Firestore Timestamp to JS Date
            const dateB = b.date?.toDate(); // Convert Firestore Timestamp to JS Date
            return dateA - dateB; // Descending order
          });

          // Assuming you want to set the balance based on the first wallet document
          setUserData((prevData) => ({
            ...prevData,
            balance: walletData[0]?.balance || 0,
          }));

          setWalletLogs(walletData); // Store all wallet logs
        } else {
          console.error("No wallet documents found for the user!");
        }
      } catch (error) {
        console.error("Error fetching user wallet:", error);
      }
    }
  };

  const handleTabClick = (tab) => {
    setCurrentTab(tab);
    if (tab === "userOrders") {
      fetchUserOrders();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const userDocRef = doc(db, `Users/UsersList/DataBase`, id);

    updateDoc(userDocRef, userData)
      .then(() => {
        toast.success("Changes saved successfully");
        navigate(`/main/users`);
      })
      .catch((error) => {
        toast.error("Error saving changes");
        console.error("Error updating user:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      const userDocRef = doc(db, `Users/UsersList/DataBase`, id);
      await deleteDoc(userDocRef);

      toast.success("User deleted successfully");
      navigate(`/main/users`);
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user");
    } finally {
      setShowDeleteDialog(false);
      setLoading(false);
    }
  };

  //
  //
  // WALLET
  //

  const handleAddToWallet = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Input validation for required fields
    if (!userData.balance || !userData.depositType) {
      toast.error("Balance and Deposit Type must be filled.");
      setLoading(false);
      return;
    }

    try {
      // Fetch the current wallet_balance from the user document
      const userDocRef = doc(db, `Users/UsersList/DataBase/${id}`);
      const userDocSnap = await getDoc(userDocRef);

      // Fetch the current wallet_balance (set to 0 if it doesn't exist)

      const depositAmount = parseFloat(userData.balance);

      // Prepare the wallet transaction object
      const walletTransaction = {
        wallet_value: depositAmount, // The deposit amount
        depositType: userData.depositType, // The deposit type (e.g., Direct Deposit)
        date_created: new Date(), // The date the transaction was created
        status: userData.depositType === "directDeposit" ? "claimed" : "pending", // Set status conditionally
        givenBy: "Admin", // The user who added the balance
        user_uid: id,
      };

      // Only add `description` if it's not empty
      if (userData?.description) {
        walletTransaction.description = userData.description;
      }

      // Only add `validUntil` if it's not empty
      if (userData?.validUntil) {
        walletTransaction.validUntil = userData.validUntil;
      }

      // Only add `date_claimed` for direct deposits
      if (userData.depositType === "directDeposit") {
        walletTransaction.date_claimed = new Date(); // Add date_claimed field
      }

      // Create a new transaction record in the Wallet collection
      const walletDocRef = doc(collection(db, `${collectionPath}Wallets/UserWallets/DataBase`));
      await setDoc(walletDocRef, walletTransaction);

      // Clean form
      setUserData({
        ...userData,
        balance: "",
        depositType: "",
        validUntil: "",
        description: "",
      });

      // Fetch the updated wallet logs
      fetchUserWallet();

      toast.success("Changes saved successfully");
    } catch (error) {
      console.error("Error adding to wallet:", error);
      toast.error("Error adding to wallet");
    } finally {
      // reload the wallet logs
      fetchUserWallet && fetchUserWallet();

      setLoading(false);
    }
  };

  //
  // Wallet END
  //

  const renderDeleteDialog = () => (
    <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this user? This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowDeleteDialog(false)} color="primary">
          No
        </Button>
        <Button onClick={handleDeleteUser} color="error">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {renderDeleteDialog()}
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />
      <h2>Edit User: {userData.email}</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginLeft: 20,
        }}
      >
        <div
          onClick={() => handleTabClick("userDetails")}
          style={{
            ...styles.tabButton,
            backgroundColor: currentTab === "userDetails" ? "#ff0000" : "#d5d4d4",
            color: currentTab === "userDetails" ? "white" : "gray",
          }}
        >
          User Details
        </div>
        <div
          onClick={() => handleTabClick("userWallet")}
          style={{
            ...styles.tabButton,
            backgroundColor: currentTab === "userWallet" ? "#ff0000" : "#d5d4d4",
            color: currentTab === "userWallet" ? "white" : "gray",
          }}
        >
          User Wallet
        </div>
        <div
          onClick={() => handleTabClick("userOrders")}
          style={{
            ...styles.tabButton,
            backgroundColor: currentTab === "userOrders" ? "#ff0000" : "#d5d4d4",
            color: currentTab === "userOrders" ? "white" : "gray",
          }}
        >
          User Orders
        </div>
      </div>

      {currentTab === "userDetails" && (
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={{ padding: "20px" }}>
            <div style={styles.formElement}>
              <label style={styles.formLabel}>Email:</label>
              <input
                type="email"
                value={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                style={styles.formInput}
                disabled
              />
            </div>
            <div style={styles.formElement}>
              <label style={styles.formLabel}>First Name:</label>
              <input
                type="text"
                value={userData.firstName}
                onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                style={styles.formInput}
              />
            </div>
            <div style={styles.formElement}>
              <label style={styles.formLabel}>Last Name:</label>
              <input type="text" value={userData.lastName} onChange={(e) => setUserData({ ...userData, lastName: e.target.value })} style={styles.formInput} />
            </div>

            <div style={styles.formElement}>
              <label style={styles.formLabel}>Suspended:</label>
              <input
                type="checkbox"
                checked={userData.suspended}
                onChange={(e) => setUserData({ ...userData, suspended: e.target.checked })}
                style={styles.formCheckbox}
              />
            </div>
            <button type="submit" disabled={loading} style={styles.button}>
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      )}
      {currentTab === "userWallet" && (
        <div style={{ padding: "20px" }}>
          <label style={{ ...styles.formLabel }}>Adding on wallet</label>
          <div style={{ ...styles.formElement }}>
            <form
              onSubmit={handleAddToWallet}
              style={{
                ...styles.formElement,
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                width: "100%",
              }}
            >
              <input
                type="text"
                value={userData.balance}
                onChange={(e) => setUserData({ ...userData, balance: e.target.value })}
                style={{ ...styles.formInput, marginRight: 10, width: "100px" }}
              />
              {/** DROPDOWN */}
              <select
                style={{ ...styles.formInput, width: "200px", marginRight: 10 }}
                value={userData.depositType} // Bind value to userData.depositType
                onChange={(e) => setUserData({ ...userData, depositType: e.target.value })}
              >
                <option value="" style={{ height: 29, padding: 10 }}></option>
                <option value="directDeposit" style={{ height: 29, padding: 10 }}>
                  Direct Deposit
                </option>
              </select>

              {/** description */}
              <input
                type="text"
                value={userData.description}
                onChange={(e) => setUserData({ ...userData, description: e.target.value })}
                style={{ ...styles.formInput, flex: 1, marginRight: 10 }}
              />
              <button type="submit" disabled={loading} style={styles.button}>
                {loading ? "Adding..." : "Add to Wallet"}
              </button>
            </form>
          </div>
          <div style={{ marginTop: 20 }}>
            <label style={{ ...styles.formLabel }}>Wallet Logs:</label>
            {walletLogs && walletLogs.length > 0 ? (
              walletLogs
                ?.sort((a, b) => {
                  const dateA = a.date_claimed?.toDate(); // Convert Firebase Timestamp to Date
                  const dateB = b.date_claimed?.toDate(); // Convert Firebase Timestamp to Date

                  return dateB - dateA; // Ascending order (earliest first)
                })
                ?.map((log, index) => {
                  let deposit_type;
                  switch (log.depositType) {
                    case "directDeposit":
                      deposit_type = <div style={{ ...styles.depositLabel, background: "green" }}>Direct Deposit</div>;
                      break;
                    case "system":
                      deposit_type = <div style={{ ...styles.depositLabel, background: "green" }}>System Deposit</div>;
                      break;
                    case "order_payment":
                      deposit_type = <div style={{ ...styles.depositLabel, background: "gold", color: "black" }}>Order Payment</div>;
                      break;
                    default:
                      deposit_type = <div style={{ ...styles.depositLabel, background: "silver" }}>Unknown</div>;
                  }

                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 10,
                      }}
                    >
                      <div
                        style={{
                          ...styles.walletRow,
                          fontWeight: 500,
                          width: 150,
                        }}
                      >
                        {log.wallet_value > 0 ? (
                          <span style={{ color: "green" }}>
                            <span style={{ opacity: 0.5 }}>$</span> +{log.wallet_value}
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            {" "}
                            <span style={{ opacity: 0.5 }}>$</span> -{Math.abs(log?.wallet_value || 0)}
                          </span>
                        )}
                      </div>

                      <div style={{ ...styles.walletRow, marginRight: 10 }}>{deposit_type}</div>

                      <div style={{ ...styles.walletRow, marginRight: 10 }}>{log?.date_created?.toDate().toLocaleDateString() || "n/a"}</div>

                      {log.givenBy && <div style={{ ...styles.walletRow, marginRight: 10, fontSize: 12 }}>{log.givenBy}</div>}
                      <div
                        style={{
                          ...styles.walletRow,
                          flex: 1,
                          textAlign: "left",
                          fontSize: 12,
                          color: "gray",
                        }}
                      >
                        {log.description}
                      </div>
                      <div
                        onClick={() => {
                          alert(log.id);
                          deleteDoc(doc(db, `${collectionPath}Wallets/UserWallets/DataBase`, log.id));
                          fetchUserWallet();
                        }}
                        style={{
                          ...styles.walletRow,
                          color: "red",
                          fontSize: 12,
                          borderRadius: 30,
                          border: "1px solid silver",
                          width: 100,
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        DELETE
                      </div>
                    </div>
                  );
                })
            ) : (
              <div>No logs found</div>
            )}
          </div>
        </div>
      )}
      {currentTab === "userOrders" && (
        <div style={{ padding: "20px" }}>
          <h3>User Orders</h3>
          {userOrders.length > 0 ? (
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>Order ID</th>
                  <th style={styles.tableHeader}>Date</th>
                  <th style={styles.tableHeader}>Total Value</th>
                  <th style={styles.tableHeader}>Number of Items</th>
                  <th style={styles.tableHeader}>Payment Type</th>
                  <th style={styles.tableHeader}>Wallet Deduction</th>
                </tr>
              </thead>
              <tbody>
                {userOrders.map((order) => (
                  <React.Fragment key={order.id}>
                    {/* Order Summary Row */}
                    <tr style={styles.tableRow} onClick={() => toggleOrderDetails(order.id)}>
                      <td style={styles.tableCell}>{order.id}</td>
                      <td style={styles.tableCell}>{order.created_at?.toDate().toLocaleString() || "N/A"}</td>
                      <td style={styles.tableCell}>${order.totalPrice?.toFixed(2) || "0.00"}</td>
                      <td style={styles.tableCell}>{order.items?.length || 0}</td>
                      <td style={styles.tableCell}>{order.paymentType || "N/A"}</td>
                      <td style={styles.tableCell}>${order.walletDeduction?.toFixed(2) || "0.00"}</td>
                    </tr>

                    {/* Expanded Order Details */}
                    {expandedOrderId === order.id && (
                      <tr>
                        <td colSpan={6} style={styles.expandedRow}>
                          <h4>Order Details</h4>
                          <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                              <tr>
                                <th style={styles.itemHeader}>Item Name</th>
                                <th style={styles.itemHeader}>Quantity</th>
                                <th style={styles.itemHeader}>Final Price</th>
                                <th style={styles.itemHeader}>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.items.map((item, index) => (
                                <tr key={index}>
                                  <td style={styles.itemCell}>{item.product_name}</td>
                                  <td style={styles.itemCell}>{item.quantity}</td>
                                  <td style={styles.itemCell}>${item.final_price?.toFixed(2) || "0.00"}</td>
                                  <td style={styles.itemCell}>${(item.final_price * item.quantity).toFixed(2)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No orders found for this user.</p>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  formCheckbox: {
    marginTop: "5px",
    padding: "10px 15px",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    height: 42,
    margin: 0,
  },
  //
  tabButton: {
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 5,
    borderRadius: "5px 5px 0 0",
    fontSize: 12,
  },
  //
  walletRow: {
    alignItems: "center",
    display: "flex",
    padding: "5px 0px",
  },
  depositLabel: {
    padding: "5px 10px",
    borderRadius: 25,
    color: "white",
    fontSize: 12,
  },
  // user orders
  tableHeader: {
    textAlign: "left",
    padding: "10px",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #ccc",
  },
  tableRow: {
    cursor: "pointer",
    borderBottom: "1px solid #eee",
  },
  tableCell: {
    padding: "10px",
  },
  expandedRow: {
    backgroundColor: "#f9f9f9",
    padding: "20px",
    border: "1px solid #ddd",
  },
  itemHeader: {
    textAlign: "left",
    padding: "10px",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #ccc",
  },
  itemCell: {
    padding: "10px",
    borderBottom: "1px solid #eee",
  },
};

export default EditUser;
