import React, { useEffect, useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { collection, getDocs, deleteDoc, doc, getDoc, updateDoc, writeBatch, increment } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../../config";
import { EnvContext } from "../../../context/EnvContext";

const VenmoPendingPayments = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [carts, setCarts] = useState([]);
  const [approving, setApproving] = useState(null); // Track the cart being approved
  const [cancelling, setCancelling] = useState(null); // Track the cart being cancelled
  const [cancelReason, setCancelReason] = useState(""); // Track the selected cancel reason
  const [loading, setLoading] = useState(false); // Track the loading state of the API request

  useEffect(() => {
    const fetchPendingCarts = async () => {
      const cartsCollectionRef = collection(db, `${collectionPath}Carts/CartsList/DataBase`);
      const querySnapshot = await getDocs(cartsCollectionRef);

      const cartsData = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((cart) => cart.status === "venmo_payment_pending")
        .sort((a, b) => {
          const dateA = a.date?.seconds ? new Date(a.date.seconds * 1000) : new Date(a.date);
          const dateB = b.date?.seconds ? new Date(b.date.seconds * 1000) : new Date(b.date);
          return dateA - dateB; // Sort by date in ascending order (older first)
        });

      setCarts(cartsData);
    };

    fetchPendingCarts();
  }, [db]);

  const formatDate = (dateStr) => {
    let date;

    // Check if the date is a Firestore Timestamp
    if (dateStr?.seconds) {
      // Convert Firestore Timestamp to JavaScript Date object
      date = new Date(dateStr.seconds * 1000);
    } else {
      // If it's not a Firestore Timestamp, assume it's a regular date string
      date = new Date(dateStr);
    }

    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const handleApproveClick = (cartId) => {
    setApproving(cartId);
    setCancelling(null); // Clear cancelling state if it's set
  };

  const handleCancelClick = () => {
    setApproving(null);
    setCancelling(null);
  };

  const handleConfirmClick = async (cart, status) => {
    setLoading(true); // Start loading
    console.log("Confirming payment for cart:", cart.id);

    try {
      // Call the Firebase function to move the cart to Orders and delete it
      const response = await fetch(`${config.apiConfig.API_ENDPOINT_URL}${environment === "staging" ? "/api/dev" : "/api"}/createOrders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart_id: cart.id,
          status: status,
          paymentType: "Venmo",
        }),
      });

      if (response.ok) {
        alert("Payment approved and cart moved to Orders.");

        // Delete the cart from Firestore
        const cartDocRef = doc(db, `${collectionPath}Carts/CartsList/DataBase`, cart.id);
        await deleteDoc(cartDocRef);

        // Remove the cart from the state
        setCarts(carts.filter((c) => c.id !== cart.id));
        setApproving(null);
      } else {
        console.error("Failed to move cart to orders and delete it.");
      }
    } catch (error) {
      console.error("Error confirming payment and deleting cart:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleCancelPaymentClick = (cartId) => {
    setCancelling(cartId);
    setApproving(null); // Clear approving state if it's set
  };

  const handleSelectCancelReason = (reason) => {
    setCancelReason(reason);
  };

  const handleConfirmCancel = async (cart) => {
    setLoading(true); // Start loading
    console.log("Cancelling payment for cart:", cart.id, "Reason:", cancelReason);

    try {
      // 1. Update the cart status to 'canceled' and set the status_description
      const cartDocRef = doc(db, `${collectionPath}Carts/CartsList/DataBase`, cart.id);
      await updateDoc(cartDocRef, {
        status: "canceled",
        status_description: cancelReason,
      });

      // 2. Retrieve the Items from the subcollection
      const itemsCollectionRef = collection(cartDocRef, "Items");
      const itemsSnapshot = await getDocs(itemsCollectionRef);
      const cartItems = itemsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (!cartItems.length) {
        console.log("No items found in the cart to update stock.");
        return;
      }

      // Log the cartItems to see their structure
      console.log("cartItems", cartItems);

      const batch = writeBatch(db);

      for (const item of cartItems) {
        // Log the item to inspect its structure
        console.log("Item object:", item);

        // Ensure you are referencing the correct field for listingId
        const listingId = item.seller_product_id; // Adjust this if the field is named differently
        if (!listingId) {
          console.error("No listingId found in item:", item);
          continue; // Skip this item if there's no listingId
        }

        console.log("Updating stock for item with listingId:", listingId);
        const listingRef = doc(db, `${collectionPath}Listings/ListingsList/DataBase`, listingId); // Access the Listings collection using listingId
        const listingDoc = await getDoc(listingRef);
        const listingData = listingDoc.data();

        if (listingData && listingData.listing_stock_amount !== -1) {
          // Only update if listing_stock_amount is not -1 (unlimited)
          batch.update(listingRef, {
            listing_stock_amount: increment(item.quantity), // Increment the stock amount by the quantity in the cart
          });
        }
      }

      await batch.commit();

      // 3. Remove the cart from the state
      setCarts(carts.filter((c) => c.id !== cart.id));
      setCancelling(null);

      alert("Payment cancelled and stock updated.");
    } catch (error) {
      console.error("Error cancelling payment and updating stock:", error);
    } finally {
      setLoading(false); // Stop loading
      setCancelReason(""); // Reset cancel reason
    }
  };

  return (
    <div>
      <h2>{environment === "staging" && <span style={{ background: "#dae121", marginRight: 5 }}>Staging</span>}Orders</h2>
      <table style={{ width: "100%" }}>
        <thead style={{ background: "#efefef", padding: "10px" }}>
          <tr>
            <th style={{ textAlign: "left", fontWeight: "200", padding: "10px" }}>User</th>
            <th style={{ textAlign: "left", fontWeight: "200", padding: "10px" }}>Date</th>
            <th style={{ textAlign: "left", fontWeight: "200", padding: "10px" }}>Cart Sooperstock ID</th>
            <th style={{ textAlign: "left", fontWeight: "200", padding: "10px" }}>Total Amount</th>
            <th
              style={{
                textAlign: "left",
                fontWeight: "200",
                padding: "10px",
                width: 200,
              }}
            >
              Payment Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {carts.map((cart) => (
            <tr key={cart.id}>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "200",
                  padding: "10px",
                }}
              >
                {`${cart.shipmentAddress.address_name}, ${cart.shipmentAddress.zipCode}`}
                <br />
                <span style={{ fontSize: 10 }}>{`ID#${cart.user_id}`}</span>
                <br />
                <span
                  style={{
                    fontSize: 10,
                  }}
                >{`BOID#${cart.cart_sooperstock_ID} / ${cart.status} `}</span>
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "200",
                  padding: "10px",
                }}
              >
                {formatDate(cart.date)}
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "200",
                  padding: "10px",
                }}
              >
                {cart.cart_sooperstock_ID}
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "200",
                  padding: "10px",
                }}
              >
                ${cart.totalAmount}
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "200",
                  padding: "10px",
                }}
              >
                {approving === cart.id ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "silver",
                        color: "white",
                        textAlign: "center",
                        padding: "10px 0",
                        cursor: "pointer",
                        width: "45%",
                      }}
                      onClick={handleCancelClick}
                      disabled={loading && approving === cart.id} // Disable only if it's the same cart being confirmed
                    >
                      Cancel
                    </div>
                    <div
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        textAlign: "center",
                        padding: "10px 0",
                        cursor: "pointer",
                        width: "45%",
                      }}
                      onClick={() => handleConfirmClick(cart, "in_progress")}
                      disabled={loading && approving === cart.id} // Disable only if it's the same cart being confirmed
                    >
                      {loading && approving === cart.id ? "Confirming..." : "Confirm"}
                    </div>
                  </div>
                ) : cancelling === cart.id ? (
                  <div>
                    <div
                      style={{
                        marginBottom: "10px",
                        cursor: "pointer",
                        fontWeight: cancelReason === "issue_payment" ? "600" : "200",
                      }}
                      onClick={() => handleSelectCancelReason("issue_payment")}
                    >
                      Issue with payment
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        cursor: "pointer",
                        fontWeight: cancelReason === "backorder" ? "600" : "200",
                      }}
                      onClick={() => handleSelectCancelReason("backorder")}
                    >
                      Product on backorder
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        cursor: "pointer",
                        fontWeight: cancelReason === "account_issue" ? "600" : "200",
                      }}
                      onClick={() => handleSelectCancelReason("account_issue")}
                    >
                      Account issue
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        cursor: "pointer",
                        fontWeight: cancelReason === "other" ? "600" : "200",
                      }}
                      onClick={() => handleSelectCancelReason("other")}
                    >
                      Other
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "silver",
                          color: "white",
                          textAlign: "center",
                          padding: "10px 0",
                          cursor: "pointer",
                          width: "45%",
                        }}
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </div>
                      <div
                        style={{
                          backgroundColor: "green",
                          color: "white",
                          textAlign: "center",
                          padding: "10px 0",
                          cursor: "pointer",
                          width: "45%",
                        }}
                        onClick={() => handleConfirmCancel(cart)}
                        disabled={loading && cancelling === cart.id} // Disable only if it's the same cart being cancelled
                      >
                        {loading && cancelling === cart.id ? "Cancelling..." : "Confirm"}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        textAlign: "center",
                        padding: "10px 0",
                        cursor: "pointer",
                        width: "45%",
                      }}
                      onClick={() => handleApproveClick(cart.id)}
                    >
                      Approve
                    </div>
                    <div
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        textAlign: "center",
                        padding: "10px 0",
                        cursor: "pointer",
                        width: "45%",
                      }}
                      onClick={() => handleCancelPaymentClick(cart.id)}
                    >
                      Cancel
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VenmoPendingPayments;
