import React, { useEffect, useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import { EnvContext } from "../../../context/EnvContext";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const MessagingMail = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore(); // Get Firestore instance from context

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  //
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messagesCollectionRef = collection(db, `Messages/Mail/DataBase`);
        const querySnapshot = await getDocs(messagesCollectionRef);

        const messagesArray = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          messagesArray.push({
            id: doc.id,
            ...data,
          });
        });

        // Sort messages by latest `endTime` (if exists) or `startTime`
        messagesArray.sort((a, b) => {
          const timeA = getFirebaseDate(a.delivery?.endTime || a.delivery?.startTime);
          const timeB = getFirebaseDate(b.delivery?.endTime || b.delivery?.startTime);
          return timeB - timeA; // Descending order
        });

        setMessages(messagesArray);
      } catch (error) {
        console.error("Error fetching messages:", error);
        toast.error("Error fetching messages");
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [db, collectionPath]);

  const getFirebaseDate = (firebaseTimestamp) => {
    if (firebaseTimestamp && firebaseTimestamp.seconds) {
      return new Date(firebaseTimestamp.seconds * 1000); // Convert seconds to milliseconds
    }
    return null;
  };

  const formatShortTime = (time) => {
    if (time) {
      return time.toLocaleString(); // Formats the Date object to a human-readable string
    }
    return "N/A";
  };

  // DELETE MESSAGE
  const handleOpenDialog = (message) => {
    setSelectedMessage(message);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedMessage(null);
    setOpenDialog(false);
  };

  const handleDeleteMessage = async () => {
    try {
      if (selectedMessage) {
        const messageDocRef = doc(db, `Messages/Mail/DataBase`, selectedMessage.id);
        await deleteDoc(messageDocRef); // Delete the selected message from Firestore
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== selectedMessage.id)); // Update UI
        toast.success("Message deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting message:", error);
      toast.error("Failed to delete message");
    } finally {
      handleCloseDialog();
    }
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="delete-dialog-title" aria-describedby="delete-dialog-description">
        <DialogTitle id="delete-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">Are you sure you want to delete this message? This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteMessage} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />
      <h2>Messages</h2>
      {loading ? (
        <p>Loading messages...</p>
      ) : (
        <table style={styles.table}>
          <thead>
            <tr style={{ backgroundColor: "rgba(49,57,66,.1)" }}>
              <th style={styles.th}>Message ID</th>
              <th style={styles.th}>To</th>
              <th style={styles.th}>BCC</th>
              <th style={styles.th}>Status</th>
              <th style={styles.th}>Time</th>
              <th style={styles.th}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => {
              let status;
              if (message.delivery?.state === "SUCCESS") {
                status = (
                  <div style={{ background: "green", color: "white", padding: "3px 10px", borderRadius: 100, display: "flex", fontSize: "12px" }}>Success</div>
                );
              } else if (message.delivery?.state === "ERROR") {
                status = (
                  <div style={{ background: "red", color: "white", padding: "3px 10px", borderRadius: 100, display: "flex", fontSize: "12px" }}>Error</div>
                );
              } else {
                status = "No State";
              }
              return (
                <tr key={message.id} style={styles.row}>
                  <td style={{ ...styles.td, fontSize: 10 }}>{message.id}</td>
                  <td style={styles.td}>{Array.isArray(message.to) ? message.to.join(", ") : message.to || "N/A"}</td>
                  <td style={styles.td}>{Array.isArray(message.bcc) ? message.bcc.join(", ") : message.bcc || "-"}</td>
                  <td style={{ ...styles.td, display: "flex", justifyContent: "flex-start" }}>{status}</td>
                  <td style={styles.td}>{formatShortTime(getFirebaseDate(message.delivery?.endTime || message.delivery?.startTime))}</td>
                  <td style={{ ...styles.td }}>
                    <Button variant="text" color="error" size="small" onClick={() => handleOpenDialog(message)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
  td: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    color: "gray",
  },
  row: {
    cursor: "pointer",
  },
};

export default MessagingMail;
