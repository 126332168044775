import React, { useState, useEffect, useContext } from "react";
import { useFirestore } from "../firebase/FirestoreContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const PaymentGateways = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [defaultPaymentGateway, setDefaultPaymentGateway] = useState("Stripe");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDocRef = doc(db, `${collectionPath}SooperstockSettings/PaymentGateways`);
      const docSnap = await getDoc(settingsDocRef);

      if (docSnap.exists()) {
        setDefaultPaymentGateway(docSnap.data().defaultPaymentGateway || "Stripe"); // default to Stripe if not set
      } else {
        console.error("No such document!");
      }
    };

    fetchSettings();
  }, [db]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const settingsDocRef = doc(db, `${collectionPath}SooperstockSettings/PaymentGateways`);

    try {
      await setDoc(settingsDocRef, { defaultPaymentGateway }, { merge: true });
      toast.success("Payment gateway settings saved successfully");
    } catch (error) {
      toast.error(`Error saving settings: ${error.message}`);
      console.error("Error updating payment gateway settings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOptionChange = (e) => {
    setDefaultPaymentGateway(e.target.value); // Set selected option
  };

  return (
    <div style={{ padding: 10 }}>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />
      <h2>{environment === "staging" && <span style={{ background: "#dae121", marginRight: 5 }}>Staging</span>}Payment Gateway Settings</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Select Default Payment Gateway:</label>
            <div style={styles.radioContainer}>
              <div style={styles.radioItem}>
                <input
                  type="radio"
                  id="stripe"
                  name="defaultPaymentGateway"
                  value="Stripe"
                  checked={defaultPaymentGateway === "Stripe"}
                  onChange={handleOptionChange}
                  style={styles.radio}
                />
                <label htmlFor="stripe" style={styles.radioLabel}>
                  Stripe
                </label>
              </div>
              <div style={styles.radioItem}>
                <input
                  type="radio"
                  id="square"
                  name="defaultPaymentGateway"
                  value="Square"
                  checked={defaultPaymentGateway === "Square"}
                  onChange={handleOptionChange}
                  style={styles.radio}
                />
                <label htmlFor="square" style={styles.radioLabel}>
                  Square
                </label>
              </div>
            </div>
          </div>
          <button
            type="submit"
            style={{
              ...styles.button,
              backgroundColor: loading ? "silver" : "#478169",
            }}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  container: {
    margin: "0 auto",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  formLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
  },
  radioContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  radioItem: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
  },
  radio: {
    marginRight: "10px",
    transform: "scale(1.2)",
  },
  radioLabel: {
    fontSize: "16px",
    color: "#333",
  },
  button: {
    marginTop: "20px",
    padding: "10px 40px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
};

export default PaymentGateways;
