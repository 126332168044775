import React, { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const Brands = () => {
  const environment = useContext(EnvContext);

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  return (
    <div>
      <div style={styles.header}>
        <h2>{environment === "staging" && <span style={{ background: "#dae121", marginRight: 5 }}>Staging</span>}Brands</h2>
        <div style={styles.buttonGroup}>
          <Link to={`/main/brands/new`}>
            <button style={styles.button}>New Brand</button>
          </Link>
          <Link to={`/main/brands/edit`}>
            <button style={styles.button}>Edit Brands</button>
          </Link>
        </div>
      </div>

      {/*  */}
      <div style={{ padding: "10px" }}>
        <Outlet />
      </div>
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "white",
    borderBottom: "1px solid #ccc",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#313942",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  buttonGroup: {
    display: "flex",
    gap: "10px", // Space between buttons
  },
};

export default Brands;
