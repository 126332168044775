const config = {
  apiConfig: {
    //API_ENDPOINT_URL: "http://127.0.0.1:5001/sooperstock-app/us-central1",
    API_ENDPOINT_URL: "https://us-central1-sooperstock-app.cloudfunctions.net",
  },

  // enveironment: "staging" | "production"
  enveironment: {
    production: {
      collectionPath: "",
    },
    staging: {
      collectionPath: "_DEVELOPMENT/stageapi/", // "_DEVELOPMENT/stageapi/",
    },
  },

  //
  effectsOptions: ["Relaxing, restful", "Energizing, upbeat", "Chill, happy", "Buzzy, happy", "Uplifting, fun", "Awareness, creative"],
  productTypeOptions: ["Plant", "Magic"],
  productCategoryOptions: ["Flower", "Preroll", "Carts", "Pods", "Dispo", "Gummy", "Chocolate", "Beverage", "Concentrate", "Battery", "Accessory", "Other"],
  weightUnitsOptions: ["gram", "mg", "oz", "mL", "fl oz"],
  strain_typeOptions: ["Indica", "Sativa", "Hybrid", "I-Hybrid", "S-Hybrid", "Other"],
};

export default config;
