import React, { useState, useEffect, useContext } from "react";
import { useFirestore } from "../firebase/FirestoreContext";
import { doc, getDoc, setDoc, updateDoc, deleteField } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const WalletSettings = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [settingsData, setSettingsData] = useState({
    minWalletPayment: "",
    walletEnabled: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDocRef = doc(db, `${collectionPath}SooperstockSettings`, "Wallet");
      const docSnap = await getDoc(settingsDocRef);

      if (docSnap.exists()) {
        setSettingsData(docSnap.data());
      } else {
        console.error("No such document!");
      }
    };

    fetchSettings();
  }, [db, collectionPath]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate price format
    const pricePattern = /^$|^\d+$/;

    if (!pricePattern.test(settingsData.minWalletPayment)) {
      toast.error("Minimum Wallet Payment must be a valid number in cents");
      setLoading(false);
      return;
    }

    // Construct the updated data object
    let updatedSettingsData = {
      ...settingsData,
      minWalletPayment: settingsData.minWalletPayment ? parseInt(settingsData.minWalletPayment, 10) : null,
    };

    // Prepare an object for deleting fields in Firestore
    let fieldsToDelete = {};

    Object.keys(updatedSettingsData).forEach((key) => {
      if (updatedSettingsData[key] === null || updatedSettingsData[key] === "" || isNaN(updatedSettingsData[key])) {
        fieldsToDelete[key] = deleteField(); // Mark the field for deletion
        delete updatedSettingsData[key]; // Also remove it from the update object
      }
    });

    const settingsDocRef = doc(db, `${collectionPath}SooperstockSettings`, "Wallet");

    try {
      const docSnap = await getDoc(settingsDocRef);
      if (docSnap.exists()) {
        if (Object.keys(fieldsToDelete).length > 0) {
          await updateDoc(settingsDocRef, fieldsToDelete);
        }
        if (Object.keys(updatedSettingsData).length > 0) {
          await updateDoc(settingsDocRef, updatedSettingsData);
        }
      } else {
        await setDoc(settingsDocRef, updatedSettingsData);
      }
      toast.success("Settings saved successfully");
    } catch (error) {
      toast.error(`Error saving settings: ${error.message}`);
      console.error("Error updating Wallet settings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettingsData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div style={{ padding: 10 }}>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />
      <h2>{environment === "staging" && <span style={{ background: "#dae121", marginRight: 5 }}>Staging</span>}Wallet Settings</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Minimum Wallet Payment (in cents):</label>
            <input
              type="text"
              name="minWalletPayment"
              value={settingsData.minWalletPayment}
              onChange={handleChange}
              style={styles.formInput}
              pattern="\d*"
              placeholder="0.00"
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Enable Wallet Usage:</label>
            <input type="checkbox" name="walletEnabled" checked={settingsData.walletEnabled} onChange={handleChange} style={styles.formCheckbox} />
          </div>
          <button
            type="submit"
            style={{
              ...styles.button,
              backgroundColor: loading ? "silver" : "#478169",
            }}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  formCheckbox: {
    marginTop: 10,
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    height: 50,
  },
};

export default WalletSettings;
